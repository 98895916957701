<script setup>
import { RouterLink, RouterView } from 'vue-router'
import Header from './components/environment/Header.vue'
import { headerHeight } from './services/HeaderState';
import Footer from './components/environment/Footer.vue'
import { footerHeight } from './services/FooterState';
</script>

<template>
  <div class="app" id="app" :style="{ 'margin-top': headerHeight, 'margin-bottom': footerHeight }">
    <Header />
    <RouterView style="transition: 0.3s ease-out;" />
    <Footer />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>