<template>
    <main>
        <h1 class="text-white fw-bold">Login</h1>
        <div class="row">
            <div class="col-12">
                <div class="form-group container">
                    <form @submit.prevent="login()" id="pacienteLogin" style="text-align: left !important; min-width: 400px;">
                        <div class="mb-3">
                            <label for="username">Username:</label>
                            <input v-model="username" class="form-control" id="username" required>
                        </div>
                        <div class="mb-3">
                            <label for="password">Password:</label>
                            <input v-model="password" class="form-control" type="password" id="password" required>
                            <small class="form-text text-muted">¡Recuerda no compartir tu contraseña!</small>
                        </div>
                        <button class="btn btn-main" :disabled="loading">Login</button>
                        <a href="#" class="text-black text-small m-auto">¿Olvidó su contraseña?</a>
                    </form>
                </div>
            </div>
        </div>
        <!-- ################################################## -->
        <modalVentanaEmergente ref="popup"></modalVentanaEmergente>
    </main>
</template>

<script>
import modalVentanaEmergente from './snippets/base/VentanaEmergente.vue'
import { useAuthStore } from '../services/AuthService.js';

export default {
    components: { modalVentanaEmergente },
    name: 'Login',
    data() {
        return {
            username: '',
            password: '',
            loading: false,
        }
    },
    methods: {
        async login() {
            this.loading = true;
            try {
                const success = await useAuthStore().login(this.username, this.password);
                console.log(success);
                if (success) {
                    window.location.href = '/';
                } else {
                    // Mostrar modal de error
                    await this.$refs.popup.show({
                        title: `Incorrecto`,
                        message: `Clave o Usuario incorrecto!`,
                        okButton: 'Continuar',
                    });
                }
            } catch (error) {
                // Mostrar modal de error
                await this.$refs.popup.show({
                    title: `Error!`,
                    message: `Error interno al iniciar sesión, por favor intente más tarde. ${error.message}`,
                    okButton: 'Continuar',
                });
            } finally {
                this.loading = false;
            }
        },
    },
}
</script>