<template>
    <main>
        <div class="container ancho-general">
            <div class="mb-5">
                <p class="text-title">Facial Trainer 2.0. AI Alpha Analytics</p>
                <p class="text-subtitle">¡Recuerda subir los videos en el analizador facial previo al entrenamiento!</p>
            </div>
            <div class="row">
                <div class="col-12">
                    <!-- Formulario -->
                    <form v-if="showPatientForm" @submit.prevent="submitPatientForm">
                        <div class="mb-3 w-100">
                            <label for="id_paciente">Please enter your Patient ID:</label>
                            <input class="form-control" type="text" id="id_paciente" name="id_paciente" required v-model="idPaciente">
                        </div>
                        <button class="btn btn-main m-auto" type="submit">Start Therapy</button>
                    </form>
                    
                    <!-- Botones de control -->
                    <div v-if="showButtons" style="position: fixed; right: 20px; max-width: 200px;" :style="{ bottom: (parseInt(footerHeight) + 20) + 'px' }">
                        <button class="btn btn-secondary w-100" id="screenshot_button" type="button" @click="takeScreenshot()">Take Screenshot</button>
                        <button class="btn btn-secondary w-100" id="record_video_button" type="button" @click="recordVideo()">Record 15s Video</button>
                        <button class="btn btn-secondary w-100" id="reset_max_button" type="button" @click="resetMaxValues()">Reset MAX Values</button>
                        <button class="btn btn-tertiary w-100" id="pause_resume_button" type="button" @click="pauseResumeTherapy()">Pause Therapy</button>
                        <button class="btn btn-red w-100" id="restart_button" type="button" @click="reiniciarEntrenador()">Reiniciar Entrenador</button>
                    </div>

                    <!-- Video oculto solo para capturar frames -->
                    <video id="video_capture" autoplay playsinline style="display:none;"></video>
                    <canvas id="canvas" style="display: none;"></canvas>

                    <!-- Video Camara -->
                    <video v-if="showVideo" id="video" autoplay playsinline></video>

                    <!-- Video Demostrativo -->
                    <div v-if="showVideo" class="video-demo-container" id="video-demo">
                        <video width="320" height="240"> <!-- 'controls' fue eliminado -->
                            <source id="demo-video-source" :src="demoVideoSrc" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </div>
                    
                    <!-- Imagen visible para mostrar frames procesados -->
                    <div v-if="showVideo" id="video-container">
                        <img id="video_frame" alt="Processed Video Frame" :src="videoFrameSrc">
                    </div>
                </div>
            </div>
        </div>
        <!-- ################################################## -->
        <modalVentanaEmergente ref="popup"></modalVentanaEmergente>
    </main>
</template>

<script>
import axios from 'axios';
import { mixin } from '../../services/Mixins';
import modalVentanaEmergente from '../snippets/base/VentanaEmergente.vue'
import RecordRTC from 'recordrtc';
import { connectSocket, state, getSocket } from '../../services/SocketIO';
import { footerHeight } from '../../services/FooterState';

export default {
    components: { modalVentanaEmergente },
    mixins: [mixin],
    name: 'FacialTrainer',
    setup() {
        return { footerHeight }
    },
    data() {
        return {
            // Generales
            showPatientForm: true,
            showButtons: false,
            showVideo: false,
            // Módulo
            currentTherapyIndex: 0,
            therapyStarted: false,
            paused: false,
            videoStream: null,
            reconnectAttempts: 0,
            maxReconnectAttempts: 5,
            demoVideoSrc: '',
            idPaciente: null,
            videoFrameSrc: '',
            socket: null,
        };
    },
    computed: {
        isConnected() {
            return state.connected;
        }
    },
    methods: {
        async submitPatientForm() {
            this.showPatientForm = false;
            this.showButtons = true;
            this.showVideo = true;
            try {
                await connectSocket(this.idPaciente);
                if (this.isConnected) {
                    this.socket = getSocket();
                    console.log('¡Conectado! Realiza la acción necesaria.  ---');
                    this.reconnectAttempts = 0;
                    this.startTherapy(this.idPaciente);
                } else {
                    console.log('El socket no está conectado después de la conexión.');
                }
            } catch (error) {
                console.error('Error al conectar:', error);
            }
        },
        async startTherapy(idPaciente) {
            console.log('Entra a StartTherapy')
            try {
                await this.startVideoCapture();

                const response = await axios.post(import.meta.env.VITE_IS_PRODUCTION === "true" ? import.meta.env.VITE_API_URL_PROD + "/facial_analyzer/procesar_datos" + "" : import.meta.env.VITE_API_URL_DEV + ':8025/app6/procesar_datos', { id_paciente: idPaciente });
                console.log('Therapy data:', response.data);
                this.therapyStarted = true;
                
                setInterval(this.updateTherapyIndexFromServer, 1000);

                setTimeout(() => {
                    this.startDemoVideo(this.currentTherapyIndex);
                    this.socket.emit('start_timer', { id_paciente: idPaciente });
                }, 2000);
            } catch (error) {
                // Ventana emergente:
                const ok = await this.$refs.popup.show({
                    title: `Error!`,
                    message: `Error al iniciar terapia.`,
                    okButton: 'Continuar',
                });
                if (ok) {
                    this.$emit('close');
                }
            }
        },
        async updateTherapyIndexFromServer() {
            try {
                const response = await axios.get(import.meta.env.VITE_IS_PRODUCTION === "true" ? import.meta.env.VITE_API_URL_PROD + "/facial_analyzer/get_indice_terapia" : import.meta.env.VITE_API_URL_DEV + ':8025/facial_trainer/app6/get_indice_terapia');
                if (response.data.indice_terapia !== this.currentTherapyIndex) {
                    this.currentTherapyIndex = response.data.indice_terapia;
                    this.startDemoVideo(this.currentTherapyIndex);
                }
            } catch (error) {
                console.error('Error fetching therapy index:', error);
            }
        },
        async startDemoVideo(indice_terapia) {
            try {
                const response = await axios.post(import.meta.env.VITE_IS_PRODUCTION === "true" ? import.meta.env.VITE_API_URL_PROD + "/facial_analyzer/start_demo_video" : import.meta.env.VITE_API_URL_DEV + ':8025/facial_trainer/app6/start_demo_video', { indice_terapia });
                
                if (response.data.video_path) {
                    this.demoVideoSrc = response.data.video_path;
                } else {
                    // Ventana emergente:
                    const ok = await this.$refs.popup.show({
                        title: `Error!`,
                        message: `No hay video demostrativo disponible para esta terapia. Por favor, comuniquelo al servicio de soporte.');`,
                        okButton: 'Continuar',
                    });
                    if (ok) {
                        this.$emit('close');
                    }
                }
            } catch (error) {
                // Ventana emergente:
                const ok = await this.$refs.popup.show({
                    title: `Error!`,
                    message: `Error iniciando video demostrativo.`,
                    okButton: 'Continuar',
                });
                if (ok) {
                    this.$emit('close');
                }
            }
        },
        async startVideoCapture() {
            const videoElement = document.getElementById('video');
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');

            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
                this.videoStream = stream;
                videoElement.srcObject = stream;
                videoElement.play();

                videoElement.onloadedmetadata = () => {
                    canvas.width = videoElement.videoWidth;
                    canvas.height = videoElement.videoHeight;

                    const captureAndSendFrame = () => {
                        context.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
                        const dataURL = canvas.toDataURL('image/jpeg');
                        this.socket.emit("video_frame", { image: dataURL });
                        requestAnimationFrame(captureAndSendFrame);
                    };

                    captureAndSendFrame();
                };
            } catch (error) {
                // Ventana emergente:
                const ok = await this.$refs.popup.show({
                    title: `Error!`,
                    message: `Error al acceder a la cámara.`,
                    okButton: 'Continuar',
                });
                if (ok) {
                    this.$emit('close');
                }
            }
        },
        takeScreenshot() {
            const videoFrame = document.getElementById('video_frame');
            const canvas = document.createElement('canvas');
            canvas.width = videoFrame.width;
            canvas.height = videoFrame.height;
            const context = canvas.getContext('2d');
            const image = new Image();
            image.src = videoFrame.src;
            image.onload = () => {
                context.drawImage(image, 0, 0, canvas.width, canvas.height);
                const screenshotData = canvas.toDataURL('image/png');
                const link = document.createElement('a');
                link.href = screenshotData;
                link.download = 'screenshot.png';
                link.click();
            };
        },
        pauseResumeTherapy() {
            this.paused = !this.paused;
            this.socket.emit('pause_resume_therapy', { paused: this.paused });
        },
        recordVideo() {
            const videoElement = document.getElementById('video');
            const recorder = new RecordRTC(videoElement.srcObject, {
                type: 'video',
                mimeType: 'video/webm'
            });

            // Iniciar la grabación
            recorder.startRecording();

            setTimeout(() => {
                recorder.stopRecording(() => {
                    const blob = recorder.getBlob();
                    const videoURL = URL.createObjectURL(blob);

                    // Mostrar el video grabado en la UI
                    this.demoVideoSrc = videoURL;
                });
            }, 15000); // 15 segundos de grabación
        },
        resetMaxValues() {
            if (this.socket) {
                this.socket.emit('reset_max_values');
            }
        },
        // ####################################################################################
        // ####################################################################################
        // ####################################################################################
        reiniciarEntrenador() {
            // Reiniciar vista:
            this.showPatientForm = true;
            this.showButtons = false;
            this.showVideo = false;
            // FALTA PONERLE STOP AL VIDEO.
        },
    }
};
</script>