<template>
    <main>
        <div class="container">
            <p class="text-title">{{ title }}</p>
            <p class="text-subtitle fs-5">{{ subtitle }}</p>
            <div class="row">
                <div class="col-12">
                    <div class="form-group">
                        <form id="videoForm" :action="formAction" method="post" enctype="multipart/form-data" @submit.prevent="handleFormSubmit()" style="text-align: left !important; min-width: 400px;">
                            <div class="mb-3 w-100">
                                <label for="id_paciente">ID del Paciente:</label>
                                <input class="form-control" type="number" id="id_paciente" name="id_paciente" required>
                            </div>
                            <div class="mb-3 w-100">
                                <label for="file">Seleccionar Video:</label>
                                <input class="form-control" type="file" id="file" name="file" required>
                            </div>
                            <button class="btn btn-main m-auto" type="submit" id="submitButton">Subir Video</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- ################################################## -->
        <modalVentanaEmergente ref="popup"></modalVentanaEmergente>
    </main>
</template>

<script>
import axios from 'axios';
import { mixin } from '../../services/Mixins';
import modalVentanaEmergente from '../snippets/base/VentanaEmergente.vue'

export default {
    components: { modalVentanaEmergente },
    mixins: [mixin],
    name: 'Sincinesias',
    data() {
        return {
            routes: {
                "/sincinesias/derecha": {
                    title: "Análisis de Sincinesias: Ojo Derecho",
                    subtitle: "-CAMBIAR A INSTRUCCIONES COMPLEJAS- Subir video cerrando el ojo derecho",
                    action: import.meta.env.VITE_IS_PRODUCTION === "true" ? import.meta.env.VITE_API_URL_PROD + "/facial_analyzer/upload/app1" : import.meta.env.VITE_API_URL_DEV + ":8025/upload/app1",
                    downloadFile: "INFORME_SINCINESIA_OCULAR_DERECHA_ORAL.pdf"
                },
                "/sincinesias/izquierda": {
                    title: "Análisis de Sincinesias: Ojo Izquierdo",
                    subtitle: "-CAMBIAR A INSTRUCCIONES COMPLEJAS- Subir video cerrando el ojo izquierdo",
                    action: import.meta.env.VITE_IS_PRODUCTION === "true" ? import.meta.env.VITE_API_URL_PROD + "/facial_analyzer/upload/app2" : import.meta.env.VITE_API_URL_DEV + ":8025/upload/app2",
                    downloadFile: "INFORME_SINCINESIA_OCULAR_IZQUIERDA_ORAL.pdf"
                },
                "/sincinesias/sonrisa": {
                    title: "Análisis de Sincinesias: Sonrisa",
                    subtitle: "-CAMBIAR A INSTRUCCIONES COMPLEJAS- Subir video sonriendo",
                    action: import.meta.env.VITE_IS_PRODUCTION === "true" ? import.meta.env.VITE_API_URL_PROD + "/facial_analyzer/upload/app3" : import.meta.env.VITE_API_URL_DEV + ":8025/upload/app3",
                    downloadFile: "INFORME_SINCINESIA_SONRISA.pdf"
                },
                "/sincinesias/general": {
                    title: "Análisis Facial General",
                    subtitle: "-CAMBIAR A INSTRUCCIONES COMPLEJAS- Subir video facial general",
                    action: import.meta.env.VITE_IS_PRODUCTION === "true" ? import.meta.env.VITE_API_URL_PROD + "/facial_analyzer/upload/app4" : import.meta.env.VITE_API_URL_DEV + ":8025/upload/app4",
                    downloadFile: "INFORME_FACE_ANALYZER_GENERAL.pdf"
                }
            },
            currentRoute: {}
        };
    },
    computed: {
        title() {
            return this.currentRoute.title;
        },
        subtitle() {
            return this.currentRoute.subtitle;
        },
        formAction() {
            return this.currentRoute.action;
        },
        downloadFileName() {
            return this.currentRoute.downloadFile;
        }
    },
    methods: {
        async handleFormSubmit() {
            const form = document.getElementById('videoForm');
            const formData = new FormData(form);
            const submitButton = document.getElementById('submitButton');
            submitButton.disabled = true;

            try {
                axios.post(this.formAction, formData, { responseType: 'blob' }).then(async response => {
                    // Crear un enlace de descarga para el PDF
                    const blob = response.data;
                    const url = window.URL.createObjectURL(blob);
                    const elementoDescarga = document.createElement('a');
                    elementoDescarga.href = url;
                    elementoDescarga.download = this.downloadFileName;
                    document.body.appendChild(elementoDescarga);
                    elementoDescarga.click();
                    elementoDescarga.remove();

                    // Notificación:
                    this.toast.success("Informe generado y descargado exitosamente!", {
                        position: "top-right",
                        timeout: 3000,
                        closeOnClick: true,
                        pauseOnFocusLoss: true,
                        pauseOnHover: true,
                        draggable: true,
                        draggablePercent: 0.5,
                        showCloseButtonOnHover: true,
                        hideProgressBar: true,
                        closeButton: "button",
                        icon: true,
                        rtl: false
                    });

                }).catch(async (error) => {
                    // Ventana emergente:
                    const ok = await this.$refs.popup.show({
                        title: `Error!`,
                        message: `Error al subir el video. Por favor, intentelo de nuevo más tarde: ${error}`,
                        okButton: 'Continuar',
                    });
                    if (ok) {
                        this.$emit('close');
                    }
                });
            } catch (error) {
                // Ventana emergente del error:
                const ok = await this.$refs.popup.show({
                    title: `Error`,
                    message: `Error inesperado: ${error}`,
                    okButton: 'Continuar',
                });
                if (ok) {
                    this.$emit('close');
                }
            } finally {
                submitButton.disabled = false;
            }
        },
        updateCurrentRoute() {
            this.currentRoute = this.routes[this.$route.path];
        }
    },
    mounted() {
        this.updateCurrentRoute();
    },
    watch: {
        '$route'(to) {
            this.updateCurrentRoute();
        }
    }
}
</script>