
        let myBackToTopButton = document.getElementById("backToTopButton");
    
        window.onscroll = function () { scrollFunction() };
    
        function scrollFunction() {
        if (document.body.scrollTop > 400 || document.documentElement.scrollTop > 400) {
            myBackToTopButton.style.display = "block";
        } else {
            myBackToTopButton.style.display = "none";
        }
        }
    
        function topFunction() {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
        }
    