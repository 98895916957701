<template>
  <transition name="fade">
    <div name="modal" v-if="isVisible">
      <div class="modal-mask">
        <div class="modal-wrapper">
          <div :class=customClass>
            <div class="modal-header fw-bold fs-5">
              <slot name="header">
                <!--  -->
                Fallback Title
              </slot>
              <slot name="close-button">
                <!-- Fallback Close Button -->
                <button class="btn btn-cerrar-modal" @click="$emit('close')" ref="closeBtn">
                    <i class="fa-solid fa-circle-xmark"></i>
                </button>
              </slot>
            </div>
            <hr class="mx-auto" />
            <div class="modal-body">
              <slot name="body">
                <!--  -->
                Fallback Body
              </slot>
            </div>
            <hr class="mx-auto"/>
            <div class="modal-footer">
              <slot name="footer">
                <!--  -->
                Fallback Footer
              </slot>
            </div>
            <slot name="components">
              <!--  -->
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'CRUDsModal',
  props: {
    isVisible: false,
    customClass: {
      type: String,
      default: 'modal-container'
    }
  },
}
</script>