// ################################################################################################################
// Nota: Los elementos de un componente tiene prioridad a los de los mixin que pueda poseer.
// ################################################################################################################

// Importar Axios -> Métodos del Mixin
import axios from 'axios'
// Notificaciones:
import { useToast } from "vue-toastification";
import { format, calculateDv, isValidRut, isFormat } from 'rutility';

// EXPORT
export const mixin = {
    inject: ['dayjs'],
    data: {
        toast: useToast(),
        messageMixin: 'Mixin..!',
    },
    computed: {
        todayDate() {
            return this.dayjs().format("DD-MM-YYYY");
        },
        todayDay() {
            return this.dayjs().format("DD");
        },
        todayMonth() {
            return this.dayjs().format("MM");
        },
        todayYear() {
            return this.dayjs().format("YYYY");
        },
    },
    methods: {
        // ######################################################################################
        // ####################################### DAYJS ########################################
        // ######################################################################################
        daysBefore($fecha, $cantidad) {
            return this.dayjs($fecha).subtract($cantidad, 'day');
        },
        monthsBefore($fecha, $cantidad) {
            return this.dayjs($fecha).subtract($cantidad, 'month');
        },
        yearsBefore($fecha, $cantidad) {
            return this.dayjs($fecha).subtract($cantidad, 'year');
        },
        startOfDay($fecha) {
            return this.dayjs($fecha).startOf('day');
        },
        startOfMonth($fecha) {
            return this.dayjs($fecha).startOf('month');
        },
        startOfYear($fecha) {
            return this.dayjs($fecha).startOf('year');
        },
        thisDate($fecha, $tipoOrden) {
            if ($fecha == null) {
                return "Sin ingreso.";
            } else {
                if ($tipoOrden) {
                    return this.dayjs($fecha).format("YYYY-MM-DD");
                } else {
                    return this.dayjs($fecha).format("DD-MM-YYYY");
                }
            }
        },
        distanceFromNow($fecha) {
            var retorno = this.dayjs($fecha).fromNow(true);
            return retorno;
        },
        distanceFromOther($fecha1, $fecha2, $type) {
            // tipos = { day, week, quarter, month, year, hour, minute, second, millisecond }
            var retorno = this.dayjs($fecha2).diff(this.dayjs($fecha1), $type);
            return retorno;
        },
        // ######################################################################################
        // ######################################## RUT #########################################
        // ######################################################################################
        rutFormat(object, property){
            var rutIngreso = object[property];
            var rutFormateado = format.dotDash(rutIngreso)
            object[property] = rutFormateado;
        },
        rutValidation(rutIngreso){
            var largoRut = rutIngreso.replace(/\D/g, '').length;
            if(largoRut > 0 && largoRut < 10){
                if(isValidRut(rutIngreso) == false){
                    this.errors.rut = 'El RUT es incorrecto.';
                }
                else{
                    delete this.errors.rut;
                }
            }
            else {
                this.errors.rut = 'El RUT está fuera de rango.';
            }
        },
        // ######################################################################################
        // ######################################################################################
        // ######################################################################################
    },
};