import { createRouter, createWebHistory } from 'vue-router';
import Login from '../components/Login.vue';
import Home from '../components/Home.vue';
import InsertarPaciente from '../components/modulos/InsertarPaciente.vue';
import Sincinesias from '../components/modulos/Sincinesias.vue';
import FacialTrainer from '../components/modulos/FacialTrainer.vue';
import Tests from '../components/Tests.vue';
import NotFound from '../components/environment/NotFound.vue';
import { useAuthStore } from '../services/AuthService.js';

// Guardia de navegación para verificar autenticación
const requireAuth = (to, from, next) => {
  setTimeout(() => {
    if (!useAuthStore().isAuthenticated) {
      next('/login');
    } else {
      next();
    }
  }, 20);
};

// Redirección de las Rutas
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    // ##### Login #####
    {
      path: '/login',
      name: 'login',
      component: Login,
    },
    // ##### Logout #####
    {
      path: '/logout',
      name: 'Logout',
      // Implementar Lógica de Cierre de Sesión:
      beforeEnter: (to, from, next) => {
        // Llamar Servicio de Autentificación:
        useAuthStore().logout();
        // Redirigir al login:
        next('/login');
      }
    },
    // ##### Rutas #####
    {
      path: '/',
      name: 'Home',
      component: Home,
      beforeEnter: requireAuth,
    },
    {
      path: '/insertarPaciente',
      name: 'InsertarPaciente',
      component: InsertarPaciente,
      beforeEnter: requireAuth,
    },
    {
      path: '/sincinesias/:tipo',
      name: 'Sincinesias',
      component: Sincinesias,
      beforeEnter: requireAuth,
      props: true
    },
    {
      path: '/facialTrainer',
      name: 'FacialTrainer',
      component: FacialTrainer,
      beforeEnter: requireAuth,
    },
    // ##### Tests #####
    {
      path: '/testing',
      name: 'Tests',
      component: Tests,
      beforeEnter: requireAuth,
    },
    // ##### 404 #####
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      component: NotFound,
      beforeEnter: requireAuth,
    },
  ]
})
export default router