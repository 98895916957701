<template>
    <main>
        <div style="display: flex; flex-wrap: nowrap;">
            <div class="ancho-general">
                <div class="fw-bolder text-justify">
                    <div class="container" style="background-color: white; padding: 20px;">
                        <p class="text-title">Instrucciones de Uso</p>
                        <!-- Carrousel -->
                        <div class="justify-content-center text-center text-white home-carousel bg-carousel">
                            <!-- Slides -->
                            <div v-if="activeIndex === 0" class="carousel-slide">
                                <div class="row">
                                    <h2 class="text-subtitle" style="color: var(--gold);">¡Bienvenido!</h2>
                                    <p>Si es tu primer ingreso a la plataforma, debes ir a la sección Ingresar Datos. Con esto obtendrás tu número de ID en la plataforma. Debes guardar y recordar este número de ID. Lo necesitarás a lo largo del uso de la plataforma.</p>
                                    <p>Si ya haz ingresado anteriormente a la plataforma, debes usar tu número de ID ya asignado anteriormente y no obtener uno nuevo.</p>
                                </div>
                            </div>
                            <div v-if="activeIndex === 1" class="carousel-slide">
                                <div class="row">
                                    <h2 class="text-subtitle" style="color: var(--gold);">Face Analyzer</h2>
                                    <p>'Face Analyzer' utiliza video para hacer el análisis facial, por lo que debes grabar los videos según las instrucciones especificas en cada sección. Para ello, son necesarios 4 videos para el análisis completo.</p>
                                    <p>Te recomendamos usar la camara trasera o principal de tu celular, idealmente no uses la camara de selfie, dado que la calidad podría verse reducida. Una vez que tengas los 4 videos en tu celular, cargalos en tu computador. Puedes hacer eso de varias formas, por email, airdrop, usb, enlace a windows, etc.</p>
                                    <p>Después de subir cada video, recibirás de forma inmediata un informe de tu análisis facial en formato PDF, este será descargado en el escritorio de tu computador. Una vez que hayas completado tu análisis facial con los 4 videos, podrás utilizar el Face Trainer con inteligencia Artificial.</p>
                                </div>
                            </div>
                            <div v-if="activeIndex === 2" class="carousel-slide">
                                <div class="row">
                                    <h2 class="text-subtitle" style="color: var(--gold);">Face Trainer</h2>
                                    <p>En el Face Trainer podrás ver tu cara, igual que en un espejo, pero con las medidas en milímetros de tus movimientos faciales. Además recibirás instrucciones por texto, audio y videos demos, de distintos ejercicios faciales. El largo de la sesión completa de entrenamiento depende de tu análisis facial y dura aproximadamente de 30 a 60 minutos.</p>
                                    <p>Te recomendamos dejar fijo sobre una mesa tu dispositivo (computador, ipad, celular). Debes permanecer mirando la pantalla durante toda la terapia. Si debes mover tu cabeza hacia el lado, puedes oprimir el boton de "Reset MAX Values" para volver a cero los valores máximos de tus movimientos.</p>
                                </div>
                            </div>
                            <div v-if="activeIndex === 3" class="carousel-slide">
                                <div class="row">
                                    <h2 class="text-subtitle" style="color: var(--gold);">¡Es momento de iniciar!</h2>
                                    <router-link to="/insertarPaciente">
                                        <button class="btn btn-sm btn-secondary">Crear Perfil</button>
                                    </router-link>
                                    <div class="mt-4">
                                        <p class="text-small">Si ya has creado tu perfil de paciente y subido los videos:</p>
                                        <router-link to="/insertarPaciente">
                                            <button class="btn btn-sm btn-secondary">Facial Trainer</button>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                            <!-- Barra de Progreso -->
                            <div class="progress-controls-container">
                                <div class="progress-container">
                                    <div class="carousel-progress-bar" :style="{ width: `${((totalTime - totalRemainingTime) / totalTime) * 100}%` }"></div>
                                    <div class="carousel-icons d-flex justify-content-between">
                                        <div v-for="(image, index) in imageUrls" class="thumbnail-container position-relative" :key="index">
                                            <img :src="image" alt="Slide Thumbnail" class="thumbnail img-fluid" :class="{ active: activeIndex === index }" @click="goToSlide(index)">
                                            <div class="thumbnail-text text-white position-absolute top-50 start-50 translate-middle">
                                                Slide {{ index + 1 }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="controls">
                                    <i @click="pauseCarousel" :class="isPaused ? 'fa-regular fa-circle-play' : 'fa-regular fa-circle-pause'"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
export default {
    name: 'Home',
    data() {
        return {
            activeIndex: 0,
            isPaused: false,
            slideTime: 5, 
            totalSlides: 4, 
            totalRemainingTime: 0, 
            interval: null,
            imageUrls: [
                '/images/FondoNeon.jpg',
                '/images/FondoNeon.jpg',
                '/images/FondoNeon.jpg',
                '/images/FondoNeon.jpg',
            ],
        }
    },
    mounted() {
        this.totalRemainingTime = this.totalTime;
        this.startCarousel();
    },
    beforeUnmount() {
        clearInterval(this.interval);
    },
    methods: {
        startCarousel() {
            this.interval = setInterval(() => {
                if (!this.isPaused) {
                    this.totalRemainingTime--;
                    if (this.totalRemainingTime % this.slideTime === 0 && this.totalRemainingTime > 0) {
                        this.activeIndex = (this.activeIndex + 1) % this.totalSlides;
                    }
                    if (this.totalRemainingTime <= 0) {
                        this.totalRemainingTime = this.totalTime;
                        this.activeIndex = 0;
                    }
                }
            }, 1000);
        },
        pauseCarousel() {
            this.isPaused = !this.isPaused;
        },
        goToSlide(index) {
            this.activeIndex = index;
            this.totalRemainingTime = this.totalTime - index * this.slideTime;
        },
    },
    computed: {
        totalTime() {
            return this.totalSlides * this.slideTime;
        },
    },
}
</script>