<template>
    <reusable-windows ref="reusable">
        <h3 style="margin-top: 0;">{{ title }}</h3>
        <p style="font-size: 18px;">{{ message }}</p>
        <div style="flex-direction: row; justify-content: space-between; text-align: center; min-width: 200px;">
            <div class="row">
                <div class="col-12">
                    <a class="btn btn-main btn-sm" @click="_confirm">{{ okButton }}</a>
                </div>
            </div>
        </div>
    </reusable-windows>
</template>

<script>
import reusableWindows from './ReusableWindows.vue'

export default {
    name: 'VentanaEmergente',
    components: { reusableWindows },
    data: () => ({
        title: undefined,
        message: undefined,
        okButton: undefined,
        resolvePromise: undefined,
        rejectPromise: undefined,
    }),
    methods: {
        show(options = {}) {
            this.title = options.title
            this.message = options.message
            this.okButton = options.okButton
            this.$refs.reusable.open()
            return new Promise((resolve, reject) => {
                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },
        _confirm() {
            this.$refs.reusable.close()
            this.resolvePromise(true)
        },
    },
}
</script>
