<template>
  <reusable-modal ref="crud" v-if="showReusableModal" :isVisible="showReusableModal" @close="showReusableModal = false" customClass="modal-container-s">
    <template v-slot:header>
      <p class="text-title">Facial Analyzer:</p>
    </template>
    <template v-slot:body>
      <div class="text-center">
        <p class="text-small">Sube tu video facial general:</p>
        <router-link to="/sincinesias/general">
          <button @click="$emit('close');" class="btn btn-secondary">Subir Video General</button>
        </router-link>
        <p class="text-small mt-5">Sube tus videos faciales para detectar sincinesias:</p>
        <router-link class="m-2" to="/sincinesias/derecha">
          <button @click="$emit('close');" class="btn btn-secondary">Subir Video Sincinesia 1</button>
        </router-link>
        <router-link class="m-2" to="/sincinesias/izquierda">
          <button @click="$emit('close');" class="btn btn-secondary">Subir Video Sincinesia 2</button>
        </router-link>
        <router-link class="m-2" to="/sincinesias/sonrisa">
          <button @click="$emit('close');" class="btn btn-secondary">Subir Video Sincinesia 3</button>
        </router-link>
      </div>
    </template>
    <template v-slot:footer>
      <!--  -->
      <p></p>
    </template>
    <template v-slot:components>
      <modalConfirmacion ref="confirmation"></modalConfirmacion>
    </template>
  </reusable-modal>
</template>
  
<script>
import modalConfirmacion from './base/Confirmacion.vue'
import reusableModal from './base/ReusableModal.vue'

export default {
  components: { modalConfirmacion, reusableModal },
  props: {
    acumulador: {
      type: Object,
      default: () => null
    },
  },
  data() {
    return {
      // Base Modal:
      buttonText: 'Continuar',
      buttonDisable: false,
      errors: [],
      showReusableModal: true,
    };
  },
  methods: {
    
  },
}
</script>