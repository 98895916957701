<template>
  <transition name="fade">
    <div name="modal" v-if="isVisible">
      <div :class="`modal-mask ${isAlert ? 'alert-animation' : ''}`">
        <div class="modal-wrapper">
          <div class="modal-dialog">
            <div class="modal-content rounded-4">
              <div class="modal-body">
                <div class="modal-container-windows">
                  <slot></slot>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'reusableModal',
  props: {
    isAlert: false,
  },
  data: () => ({
    isVisible: false,
  }),
  methods: {
    open() {
      this.isVisible = true
    },
    close() {
      this.isVisible = false
    },
  },
}
</script>
