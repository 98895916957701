import { defineStore } from 'pinia';
import axios from 'axios';

export const useAuthStore = defineStore({
  id: 'PiniaAuth',
  persist: true,
  state: () => ({
    token: null,
    isAuthenticated: false,
  }),
  actions: {
    // Inicio de Sesión Básico:
    async login(username, password) {
      try {
        const response = await axios.post(import.meta.env.VITE_IS_PRODUCTION === "true" ? import.meta.env.VITE_API_URL_PROD + "/facial_analyzer/login" : import.meta.env.VITE_API_URL_DEV + ':8025/login', { username, password });
        if (response.data === 'True') {
          this.token = response.data.token;
          this.isAuthenticated = true;
          return true;
        } else if (response.data === 'False') {
          return false;
        }
      } catch (error) {
        throw new Error('Error en la autenticación');
      }
    },
    // Cierre de Sesión:
    logout() {
      this.token = null;
      this.isAuthenticated = false;
    },
  },
});