<template>
    <footer class="text-white" :style="{ minHeight: footerHeight, maxHeight: footerHeight }">
        <div class="row justify-content-between align-items-center">
            <div class="col-md-6 text-small">
                &copy; 2024 AI Alpha Analytics. All rights reserved.
            </div>
            <div class="col-md-6 text-small">
                <ul class="nav nav-footer justify-content-end">
                    <li class="nav-item">
                        <a href="#" class="nav-link" target="_blank">AI Alpha Analytics Website</a>
                    </li>
                </ul>
            </div>
        </div>
    </footer>
</template>

<script>
import { footerHeight } from '../../services/FooterState';

export default {
    name: 'Footer',
    setup() {
        return { footerHeight }
    }
}
</script>

<style lang="scss" scoped>
    footer {
        background-color: var(--dark);
        position: fixed;
        right: 0;
        left: 0;
        bottom: 0;
        padding: 5px 30px;
        z-index: 99;
        overflow: hidden;
        font-weight: 400;
        font-size: 0.875rem;
        transition: 0.3s ease-out;
        display: flex;
        align-items: center;

        .row {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .nav-link {
            color: white;
            font-size: 14px;
        }
    }
</style>