<template>
    <main>
        <div class="container">
            <div class="row justify-content-end m-1">
                <div class="col-md-auto">
                    <!-- Test Popup -->
                    <button class="btn btn-main" type="button" @click="testPopup()">Popup</button>
                    <!-- Test Confirmation -->
                    <button class="btn btn-main" type="button" @click="testConfir()">Confirmación</button>
                </div>
            </div>
            <!-- Carrousel -->
            <div class="carousel">
                <!-- Slides -->
                <div v-if="activeIndex === 0" class="slide">
                    <div class="row text-center">
                        <h2>Slide 1</h2>
                        <p>Contenido</p>
                    </div>
                </div>
                <div v-if="activeIndex === 1" class="slide">
                    <div class="row text-center">
                        <h2>Slide 2</h2>
                        <p>Contenido</p>
                    </div>
                </div>
                <div v-if="activeIndex === 2" class="slide">
                    <div class="row text-center">
                        <h2>Slide 3</h2>
                        <p>Contenido</p>
                    </div>
                </div>
                <!-- Barra de Progreso -->
                <div class="progress-controls-container">
                    <div class="progress-container">
                        <div class="progress-bar" :style="{ width: `${((totalTime - totalRemainingTime) / totalTime) * 100}%` }"></div>
                        <div class="icons">
                            <!-- <img v-for="(image, index) in imageUrls" :key="index" :src="image" alt="Slide Thumbnail" class="thumbnail" :class="{ active: activeIndex === index }" @click="goToSlide(index)"/> -->
                            <i v-for="(slide, index) in totalSlides" :key="index" class="fa-solid fa-square icon" :class="{ active: activeIndex === index }" @click="goToSlide(index)"></i>
                        </div>
                    </div>
                    <div class="controls">
                        <i @click="pauseCarousel" :class="isPaused ? 'fa-regular fa-circle-play' : 'fa-regular fa-circle-pause'"></i>
                    </div>
                </div>
            </div>
        </div>
    </main>
    <!-- ################################################## -->
    <modalVentanaEmergente ref="popup"></modalVentanaEmergente>
    <modalConfirmacion ref="confir"></modalConfirmacion>
</template>
  
<script>
import axios from 'axios';
import { mixin } from '../services/Mixins.js'
import modalConfirmacion from './snippets/base/Confirmacion.vue'
import modalVentanaEmergente from './snippets/base/VentanaEmergente.vue'

export default {
    components: { modalVentanaEmergente, modalConfirmacion },
    name: 'Testing',
    data() {
        return {
            activeIndex: 0,
            isPaused: false,
            slideTime: 5,
            totalSlides: 3,
            totalTime: 15,
            totalRemainingTime: 15,
            interval: null,
            imageUrls: [
                '/images/FaceLab.png',
                '/images/FaceLab.png',
                '/images/FaceLab.png',
            ],
        };
    },
    methods: {
        async testPopup() {
            const ok = await this.$refs.popup.show({
                title: `Título`,
                message: `Mensaje`,
                okButton: 'Continuar',
            });
            if (ok) {
                this.$emit('close');
            }
        },
        async testConfir() {
            const ok = await this.$refs.confir.show({
                title: `Título`,
                message: `Mensaje`,
                cancelButton: 'Volver',
                okButton: 'Continuar',
            });
            if (ok) {
                this.$emit('close');
            }
        },
        startCarousel() {
            this.interval = setInterval(() => {
            // Si no está pausado
            if (!this.isPaused) {
                // Disminuye el tiempo:
                this.totalRemainingTime--;
                // Cambio a la siguiente slide:
                if (this.totalRemainingTime % this.slideTime === 0 && this.totalRemainingTime > 0) {
                    this.activeIndex = (this.activeIndex + 1) % this.totalSlides;
                }
                // Si terminaron los slides reinicia:
                if (this.totalRemainingTime <= 0) {
                    this.totalRemainingTime = this.totalTime;
                    this.activeIndex = 0;
                }
            }
            }, 1000);
        },
        pauseCarousel() {
            this.isPaused = !this.isPaused;
        },
        goToSlide(index) {
            this.activeIndex = index;
            this.totalRemainingTime = this.totalTime - index * this.slideTime;
        },
    },
    mounted() {
        this.startCarousel();
    },
    beforeUnmount() {
        clearInterval(this.interval);
    },
};
</script>

<style scoped>
.carousel {
    background-color: rgb(220, 220, 220);
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    max-width: 600px;
    margin: auto;
}

.slide {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}

.progress-controls-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.progress-container {
    flex: 1;
    position: relative;
    height: 10px;
    background-color: #e0e0e0;
    border-radius: 5px;
    margin-right: 10px;
    overflow: hidden;
}

.progress-bar {
    height: 100%;
    background-color: #ff6b6b;
    transition: width 1s linear;
}

.icons {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    right: 0;
    padding: 0 10px;
}

.thumbnail {
    width: 30px;
    height: 30px;
    border-radius: 5%;
    cursor: pointer;
    transition: transform 0.3s, border 0.3s;
    border: 2px solid #ccc;
    object-fit: cover;
    background: white;
}

.thumbnail.active,
.thumbnail:hover {
    transform: scale(1.1);
    border-color: #ff6b6b;
}

.controls {
    display: flex;
    align-items: center;
}

.controls i {
    font-size: 24px;
    cursor: pointer;
}
</style>