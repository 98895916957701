import { reactive } from "vue";
import { io } from "socket.io-client";

export const state = reactive({
  connected: false,
});

const URL = import.meta.env.VITE_IS_PRODUCTION === "true" ? import.meta.env.VITE_API_URL_PROD + "/facial_trainer" : import.meta.env.VITE_API_URL_DEV + ':8026'

let socket = null;

export function connectSocket(idPaciente) {
  return new Promise((resolve, reject) => {
    console.log("Intentando conectar con id_paciente:", idPaciente);

    if (!socket || !socket.connected) {
      // ################### CONEXIÓN ###################
      console.log('Dentro del if', URL + '/app6');
      /* socket = io(URL + '/app6', {
        query: { id_paciente: idPaciente },
        transports: ['websocket'],
      }); */
      // Eliminar esto, es lo para hacer pruebas locales.
      /* socket = io('http://127.0.0.1:8026/app6', {
        query: { id_paciente: idPaciente },
        transports: ['websocket'],
      }); */
      socket = io('http://facelabanalytics.com/app6', {
        query: { id_paciente: idPaciente },
        transports: ['websocket'],
      });
      
      socket.on("connect", () => {
        console.log('Connected to Socket.IO');
        state.connected = true;
        socket.emit("prueba_conexion", { mensaje: "Prueba desde front-end" });
        resolve();
      });
      
      socket.on("disconnect", () => {
        console.log('Disconnected from Socket.IO');
        state.connected = false;
      });
      
      socket.on("connect_error", (error) => {
        console.error('Socket.IO connection error:', error);
        reject(error);
      });
      
      // ################### COMUNICACIÓN ###################
      socket.on('processed_video_frame', (data) => {
        this.videoFrameSrc = 'data:image/jpeg;base64,' + data.frame;
      });

      // ################### BORRAR ###################
      // ################### BORRAR ###################
      socket.on("respuesta_prueba", (data) => {
        console.log("Respuesta del servidor:", data.mensaje);
      });

      socket.on("respuesta_conexion", (data) => {
        console.log("Mensaje de conexión recibido del servidor:", data.mensaje);
      });
      // ################### BORRAR ###################
      // ################### BORRAR ###################
    } else {
      console.log("Socket ya está conectado.");
      resolve();
    }
  });
}

export const getSocket = () => socket;