<template>
    <header class="text-white" :style="{ minHeight: headerHeight, maxHeight: headerHeight }">
        <div class="row">
            <!-- <div class="col-12 align-items-center"> -->
            <div class="col-auto d-flex align-items-center">
                <div class="logo">
                    <router-link to="/">
                        <img class="mx-auto d-block" src="/images/FaceLabAnalytics.png" style="width: auto; height: 100px;">
                    </router-link>
                </div>
            </div>
            <div v-if="isAuthenticated" class="col d-flex align-items-center justify-content-center">
                <nav>
                    <ul class="nav justify-content-center gap-2">
                        <li class="nav-item">
                            <router-link to="/" class="nav-link"><button class="btn btn-sm btn-main w-100">Instrucciones de uso</button></router-link>
                        </li>
                        <li class="nav-item">
                            <router-link to="/insertarPaciente" class="nav-link"><button class="btn btn-sm btn-main w-100">Insertar Paciente</button></router-link>
                        </li>
                        <li class="nav-item">
                            <div class="nav-link"><button class="btn btn-sm btn-main w-100" @click="showFacialAnalyzerRoutes = true">Facial Analyzer</button></div>
                        </li>
                        <li class="nav-item">
                            <router-link to="/facialTrainer" class="nav-link"><button class="btn btn-sm btn-main w-100">Facial Trainer</button></router-link>
                        </li>
                    </ul>
                </nav>
            </div>
            <div v-if="isAuthenticated" class="col-auto d-flex align-items-center">
                <router-link to="/logout" class="nav-link"><button class="btn btn-sm btn-red w-100">Cerrar Sesión</button></router-link>
            </div>
        </div>
    </header>
    <!-- ################################################## -->
    <modalVentanaEmergente ref="popup"></modalVentanaEmergente>
    <modalFacialAnalyzer v-if="showFacialAnalyzerRoutes" @close="showFacialAnalyzerRoutes = false"></modalFacialAnalyzer>
</template>

<script>
import modalFacialAnalyzer from '../snippets/FacialAnalyzerRoutes.vue'
import modalVentanaEmergente from '../snippets/base/VentanaEmergente.vue'
import { headerHeight } from '../../services/HeaderState';
import { useAuthStore } from '../../services/AuthService';

export default {
    components: { modalFacialAnalyzer, modalVentanaEmergente },
    name: 'Header',
    setup() {
        return { headerHeight };
    },
    data() {
        return {
            showFacialAnalyzerRoutes: false,
        }
    },
    computed: {
        isAuthenticated() {
            return useAuthStore().isAuthenticated;
        },
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
    header {
        background-color: var(--dark);
        position: fixed;
        right: 0;
        left: 0;
        top: 0;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 5px;
        padding-bottom: 5px;
        z-index: 100;
        overflow: hidden;
        font-weight: 400;
        font-size: 1rem;
        transition: 0.3s ease-out;

        .nav-link {
            color: white;
            font-size: 14px;
        }
    }
</style>