<template>
    <main>
        <div class="container ancho-general">
            <p class="text-title">Ingresar Nuevo Paciente</p>
            <p>Comienza midiendo cuidadosamente la distancia entre tus ojos en milímetros.</p>
            <div class="form-group">
                <form id="pacienteForm" @submit.prevent="insertarPaciente" style="text-align: left !important;">
                    <div class="row">
                        <p class="text-subtitle">Identificación Básica</p>
                        <div class="col-5 mb-3">
                            <label for="nombres">Nombres:</label>
                            <input class="form-control" type="text" v-model="paciente.nombres" required placeholder="Juan Carlos">
                        </div>
                        <div class="col-5 mb-3">
                            <label for="apellidos">Apellidos:</label>
                            <input class="form-control" type="text" v-model="paciente.apellidos" required placeholder="Alvarez Ojeda">
                        </div>
                        <div class="col-2 mb-3">
                            <label for="apodo">Apodo:</label>
                            <input class="form-control" type="text" v-model="paciente.apodo" placeholder="JC">
                        </div>
                    </div>
                    <hr>
                    <div class="row">
                        <p class="text-subtitle">Datos Personales</p>
                        <div class="col-3 mb-3">
                            <label for="telefono">Teléfono:</label>
                            <div class="d-flex align-items-center">
                                <input class="form-control" type="text" value="+569" disabled style="width: 80px;">
                                <input class="form-control" type="number" v-model="paciente.telefono" required placeholder="56245165" min="0">
                            </div>
                        </div>
                        <div class="col-5 mb-3">
                            <label for="email">Correo Electrónico:</label>
                            <input class="form-control" type="email" v-model="paciente.email" required placeholder="jalvarez@gmail.com">
                        </div>
                        <div class="col-3 mb-3">
                            <label for="rut">RUT:</label>
                            <input class="form-control" type="text" v-model="paciente.rut" required placeholder="20.468.795-K" @input="rutFormat(paciente, 'rut')" @change="rutValidation(paciente.rut)">
                            <div v-if="errors && errors.rut" class="text-error">
                                {{ errors.rut }}
                            </div>
                        </div>
                        <div class="col-1 mb-3">
                            <label for="edad">Edad:</label>
                            <input class="form-control" type="number" v-model="paciente.edad" required placeholder="35" min="0">
                        </div>
                        <div class="col-12 mb-3">
                            <label for="direccion">Dirección:</label>
                            <input class="form-control" type="text" v-model="paciente.direccion" required placeholder="Pl. Vergara S/N, Viña del Mar, Valparaíso, Chile">
                        </div>
                    </div>
                    <hr>
                    <p class="text-subtitle">Datos Faciales</p>
                    <div class="row">
                        <div class="col-5 mb-3">
                            <label for="distancia_intercantal">Distancia entre los ojos en milímetros (mm):</label>
                            <input class="form-control" type="number" v-model="paciente.distancia_intercantal" required placeholder="55" min="0">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 mb-3 d-flex align-items-center">
                            <p class="m-1" for="paralisis_facial">¿Presenta parálisis facial?</p>
                            <input class="form-check-input neon-border-checkbox m-1" type="checkbox" v-model="paciente.paralisis_facial" @change="fechaParalisis = null; paciente.causa_paralisis = null;">
                            <label class="text-small m-1" v-if="paciente.paralisis_facial">(si)</label>
                            <label class="text-small m-1" v-if="!paciente.paralisis_facial">(no)</label>
                        </div>
                        <div v-if="paciente.paralisis_facial" class="container text-start col-12" style="background: rgba(0, 0, 0, 0.15); width: 80%;">
                            <div class="row">
                                <div class="col-4 mb-3">
                                    <label for="fechaParalisis">Fecha de aparición de la parálisis facial:</label>
                                    <input class="form-control" type="date" v-model="fechaParalisis" required>
                                </div>
                                <div class="col-8 mb-3">
                                    <label for="causa_paralisis">Causa de la parálisis facial:</label>
                                    <input class="form-control" type="text" v-model="paciente.causa_paralisis" required placeholder="Hinchazón del nervio facial">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 mb-3 d-flex align-items-center">
                            <p class="m-1 me-2" for="quemadura_facial">¿Presenta quemaduras faciales?</p>
                            <input class="form-check-input neon-border-checkbox m-1" type="checkbox" v-model="paciente.quemadura_facial" @change="fechaQuemadura = null; paciente.causa_quemadura = null;">
                            <label class="text-small m-1" v-if="paciente.quemadura_facial">(sí)</label>
                            <label class="text-small m-1" v-if="!paciente.quemadura_facial">(no)</label>
                        </div>
                        <div v-if="paciente.quemadura_facial" class="container text-start col-12" style="background: rgba(0, 0, 0, 0.15); width: 80%;">
                            <div class="row">
                                <div class="col-4 mb-3">
                                    <label for="fechaQuemadura">Fecha de la quemadura facial:</label>
                                    <input class="form-control" type="date" v-model="fechaQuemadura" required>
                                </div>
                                <div class="col-8 mb-3">
                                    <label for="causa_quemadura">Causa de la quemadura facial:</label>
                                    <input class="form-control" type="text" v-model="paciente.causa_quemadura" required placeholder="Agua hirviendo">
                                </div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-main" type="submit">Ingresar Paciente</button>
                </form>
            </div>
        </div>
        <modalVentanaEmergente ref="popup"></modalVentanaEmergente>
        <modalConfirmacion ref="confir"></modalConfirmacion>
    </main>
</template>

<script>
import axios from 'axios';
import { mixin } from '../../services/Mixins.js'
import modalVentanaEmergente from '../snippets/base/VentanaEmergente.vue'
import modalConfirmacion from '../snippets/base/Confirmacion.vue'

export default {
    mixins: [mixin],
    components: { modalVentanaEmergente, modalConfirmacion },
    name: 'Sincinesias',
    data() {
        return {
            paciente: {
                nombres: null,
                apellidos: null,
                apodo: null,
                telefono: null,
                email: null,
                rut: null,
                edad: null,
                direccion: null,
                distancia_intercantal: null,
                paralisis_facial: false,
                fecha_paralisis: null,
                causa_paralisis: null,
                quemadura_facial: false,
                fecha_quemadura: null,
                causa_quemadura: null,
            },
            fechaParalisis: null,
            fechaQuemadura: null,
            errors: [],
        };
    },
    methods: {
        async insertarPaciente() {
            this.paciente.fecha_paralisis = this.fechaParalisis ? this.dayjs(this.fechaParalisis, 'DD/MM/YYYY').format('DD/MM/YYYY') : null;
            this.paciente.fecha_quemadura = this.fechaQuemadura ? this.dayjs(this.fechaQuemadura, 'DD/MM/YYYY').format('DD/MM/YYYY') : null;
            const ok = await this.$refs.confir.show({
                title: 'Ingresar Paciente',
                message: `Está seguro que desea ingresar al paciente '${this.paciente.nombres} ${this.paciente.apellidos}' con RUT: '${this.paciente.rut}'`,
                cancelButton: 'Volver',
                okButton: 'Continuar',
            });
            if (ok) {
                const submitButton = document.querySelector('button[type="submit"]');
                submitButton.disabled = true;

                try {
                    const response = await fetch(import.meta.env.VITE_IS_PRODUCTION === "true" ? import.meta.env.VITE_API_URL_PROD + "/facial_analyzer/insertar_paciente" : import.meta.env.VITE_API_URL_DEV + ':8025/app5/insertar_paciente', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(this.paciente),
                    });

                    const result = await response.json();

                    const ok = await this.$refs.popup.show({
                        title: `Identificador del Paciente: ${result.id_paciente}`,
                        message: `${result.mensaje}`,
                        okButton: 'Volver al inicio',
                    });
                    if (ok) {
                        this.$emit('close');
                        submitButton.disabled = false;
                    }

                } catch (error) {
                    const ok = await this.$refs.popup.show({
                        title: 'Error',
                        message: `Error al insertar el paciente: ${error}`,
                        okButton: 'Continuar',
                    });
                    if (ok) {
                        //this.$router.push('/home');
                        this.$emit('close');
                        submitButton.disabled = false;
                    }
                }
            }
        }
    }
};
</script>