<template>
    <main>
        <section class="d-flex justify-content-center align-items-center">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="text-center">
                            <h2 class="d-flex justify-content-center align-items-center">
                                <span class="orbitron-font display-1 fw-bold">4</span>
                                <i style="color: crimson;" class="fa-solid fa-circle-exclamation fa-2x"></i>
                                <span class="orbitron-font display-1 fw-bold mirror-text">4</span>
                            </h2>
                            <h2 class="text-title">Oops! ¿Estás perdido?</h2>
                            <p class="mb-5">La página a la que intentas acceder no existe.</p>
                            <a class="btn btn-main rounded-pill px-5" href="/">Volver al Inicio</a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </main>
</template>

<script>
export default {
    name: 'NotFound',
}
</script>