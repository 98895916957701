// Vue3
import { createApp } from 'vue';
import App from './App.vue';
import router from './router/Index.js';
// DayJS
import dayjs from 'dayjs';
import "dayjs/locale/es";
dayjs.locale("es");
import relativeTime from 'dayjs/plugin/relativeTime'
dayjs.extend(relativeTime);
// Pinia
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
// Toastification
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
// Boostrap 5
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
// SCSS (Propio)
import '../public/styles/main.scss';

// ######## Base ########
const app = createApp(App)
// ######## Toastification ########
app.use(Toast, {
    transition: "Vue-Toastification__bounce",
    maxToasts: 5,
    newestOnTop: true,
    filterBeforeCreate: (toast, toasts) => {
        if (toasts.filter(
            t => t.type === toast.type
        ).length !== 0) {
            // Returning false discards the toast
            return false;
        }
        // You can modify the toast if you want
        return toast;
    }
});
// ######## DayJS ########
app.provide('dayjs', dayjs);
// ######## Pinia ########
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate)
app.use(pinia);
// ######## Router + Mount ########
app.use(router).mount('#app')